@import '../../../colors';

.Loading {
  .main-loading-container {
    height: 100%;
    width: 100%;
    background-color: $brandCream;
    z-index: 1000000;

    .main-loading-container-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      text-align: center;
      width: 100%;

      .main-loading-logo-container {
        .main-loading-logo {
          height: 50px;

          -webkit-animation-name: spin;
          -webkit-animation-duration: 2000ms;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-timing-function: linear;
      
          -moz-animation-name: spin;
          -moz-animation-duration: 2000ms;
          -moz-animation-iteration-count: infinite;
          -moz-animation-timing-function: linear;
      
          -ms-animation-name: spin;
          -ms-animation-duration: 2000ms;
          -ms-animation-iteration-count: infinite;
          -ms-animation-timing-function: linear;
      
          animation-name: spin;
          animation-duration: 2000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        @-ms-keyframes spin { 
          from { 
              -ms-transform: rotateY(0deg); 
          } to { 
              -ms-transform: rotateY(360deg); 
          }
        }
        @-moz-keyframes spin { 
          from { 
              -moz-transform: rotateY(0deg); 
          } to { 
              -moz-transform: rotateY(360deg); 
          }
        }
        @-webkit-keyframes spin { 
          from { 
              -webkit-transform: translateZ(25px) rotateY(0deg); 
          } to { 
              -webkit-transform: translateZ(25px) rotateY(360deg); 
          }
        }
        @keyframes spin { 
          from { 
              transform: translateZ(25px) rotateY(0deg); 
          } to { 
              transform: translateZ(25px) rotateY(359deg); 
          }
        }
      }
    }
  }
}
