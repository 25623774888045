.Courses {

  .error-message-container {
    text-align: center;
    margin-top: 50px;
    padding: 20px;

    p {
      line-height: 1.5;
    }
  }
}