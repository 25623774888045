@import '../../../colors';

.homeContainer {
  .imageContainer {
    margin-top: -50px;
    max-width: 100%;
  }

  .homeBanner {
    color: $darkSalmon;
    margin-top: -125px;
    text-align: center;
    max-width: 500px;

    .bannerSubTitle {
      font-size: 22px;
    }
  }
}

.courseModulesContainer {
  margin-top: 16px;
}
