.NoMatch {
  text-align: center;
  padding: 0 20px;

  h1 {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  p {
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
  }
}