@import "../../../colors";

.Welcome {
  .background {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-color: $primarySalmon;
    .grass {
      position: relative;
      top: -350px;
      height: 488px;
      width: 100%;
    }
  }
  
  .welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -91px !important;
    max-width: 700px;
    text-align: center;
    z-index: 1;
  
    .welcome-content {
      margin: -130px 24px 0 24px;
    }
  }
}