$black: #000000;
$white: #FFFFFF;
$brandCherry: #E64B38;
$brandCream: #FAF7E8;

$primarySalmon: #FFB4AB;
$primarySky: #A6C4E7;
$primaryLemon: #FFD040;
$primaryPea: #AADA91;
$primaryLavender: #BB9BC5;
$primaryBlush: #EE6FBB;
$primaryApricot: #F09F54;

$darkSalmon: #592E2C;
$darkSky: #243746;
$darkLemon: #564F24;
$darkPea: #294634;
$darkLavender: #3D1152;
$darkBlush: #682145;
$darkApricot: #623C21;

$tertiaryMandarin: #FF9015;
$tertiaryGrape: #A81B8D;
$tertiaryFuchsia: #F44C7F;
$tertiaryHazel: #B06533;
$tertiaryGold: #FFC600;
$tertiaryTan: #B68150;
$tertiaryOlive: #546122;
$tertiaryColbalt: #0071CE;
$tertiaryTurquoise: #00B189;
$tertiaryMoss: #B4BD00;
$tertiaryPlum: #624B78;

$primaryColorLight: #FFEFED;
$primaryColorMedium: #F7DBD7;
$secondaryColor: #e7ac00;
$successColor: #2BB673;
$alternateColor: #00afcc;
$borderLight: rgba(0, 0, 0, 0.1);
$borderDark: rgba(0, 0, 0, 0.2);
$transparent: rgba(0, 0, 0, 0);
$linkColor: #0e70be;
