@import "./colors";

.App {
  position: relative;
  background-color: $brandCream;
  color: $darkSalmon;
  padding-bottom: 12px;
  min-height: 100vh;

  .boxShadow {
    box-shadow: 4px 4px 4px 0px #24374614;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 24px 20px 24px;
    max-width: 100vw;
    overflow: hidden;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .previous-icon {
    height: 16px;
    margin-bottom: -2px;
  }

  hr.solid {
    border: 2px solid $tertiaryGold;
    width: 50px;
  }

  hr.solid-wide {
    border: 2px solid $tertiaryGold;
    width: 100%;
    margin: 24px 0 24px 0;
  }

  .moduleLink {
    display: flex;
    background-color: $white;
    border-radius: 8px;
    margin-bottom: 16px;
    max-height: 100px;
    text-decoration: none;
    .moduleImageWrapper {
      max-width: 100px;
      min-width: 100px;
      height: 100px;
      img {
        border-radius: 8px 0 0 8px;
        width: 100%;
        height: 100%;
      }
    }
    .moduleLinkText {
      padding: 12px 16px 12px 16px;
      h3 {
        color: $darkSalmon;
        margin: 0;
      }
      p {
        color: $darkSalmon;
        margin: 4px 0 0 0;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2; 
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
  
    }
  }
  
  .containImage {
    img {
      object-fit: cover;
    }
  }

  .primaryBtn {
    background-color: $brandCherry;
    border: 2px solid $brandCherry;
    border-radius: 25px;
    color: $brandCream;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    text-transform: uppercase;
    width: 100%;
    &:hover {
      background-color: transparent;
      color: $brandCherry;
    }
  }

  .secondaryBtn {
    background-color: transparent;
    border: 2px solid $brandCherry;
    border-radius: 25px;
    color: $brandCherry;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    text-transform: uppercase;
    width: 100%;
    &:hover {
      background-color: transparent;
    }
  }

  .primary-input {
    background-color: $brandCream;
    border: 2px solid $darkSalmon;
    border-radius: 8px;
    color: $darkSalmon;
    font-size: 16px;
    height: 51px;
    margin-bottom: 16px;
    padding: 8px 16px 8px 16px;
    width: 100%;
  }

  ::placeholder {
    color: $darkSalmon;
    opacity: 1;
    font-family: Buenos Aires, sans-serif;
  }

  ::-ms-input-placeholder {
    color: $darkSalmon;
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  
    .modal-body {
      position: absolute;
      background-color: #fff;
      padding: 20px;
      padding-top: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 16px;
      box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
      max-height: calc(100vh - 5px);
      overflow: auto;
  
      @media (max-width: 700px) {
        max-height: calc(100vh - 60px);
        min-width: 90%;
      }
  
      .close-button {
        cursor: pointer;
        right: 5px;
        top: 5px;
        position: absolute;
  
        img {
          margin: 5px 5px 0 0;
          height: 24px;
        }
      }
  
      .close-button:hover {
        background-color: #eee;
      }
  
      .modal-content {
        font-weight: 600;
        font-size: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
    }
  
    @media (max-width: 550px) {
      .modalBody {
        min-width: calc(100% - 30px);
        padding: 10px;
        padding-top: 20px;
      }
    }
  }

  .text-link {
    color: $darkSalmon;
  }

  .header-text {
    font-size: 25px;
    font-weight: 600;
  }

  .coupon-container {
    margin: 24px 0 24px 0;
  }
}

