@import "../../../colors.scss";

.CourseModule {

  .header {
    text-align: center;
  }

  .back-link-container {
    align-self: flex-start;
    @media (max-width: 1240px) {
      padding: 10px 10px;
    }
    p {
      color: $brandCherry;
      font-size: 16px;
      display: inline-block;
      font-weight: bold;
      margin: 15px 0 5px;
      margin-left: 10px;
      text-transform: uppercase;
    }
  }

  .module-container {
    margin-top: 10px;

    @media (max-width: 1240px) {
      // padding: 0 10px;
    }

    .main-section {
      margin-top: 10px;

      .main-section-inner {

        h2 {
          font-size: 22px;
          font-weight: 600;
        }

        button:hover {
          opacity: .8;
        }

        button:focus {
          outline-width: 0;
        }

        .video-container {
          width: 100%;
          padding-top: 56.25%;
          position: relative;

          .video-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
          }
        }

        .title-divider {
          height: 1px;
          width: calc(100% + 20px);
          margin-left: -10px;
          background-color: #ddd;
        }

        .section-title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 24px;
        }

        .paragraph {
          white-space: pre-wrap;
        }

        .Collapsible {
          margin-bottom: 10px;
        }

        .Collapsible__trigger {
          display: inherit;
          cursor: pointer;
          padding: 13px 0 13px 0;
          font-weight: 600;

          span {
            display: flex;
            font-size: 25px;
            justify-content: space-between;
          }

          img {
            transition: all 150ms ease-in-out;
            height: 24px;
          }

          &.is-open {
            img {
              transform: rotate(180deg);
            }
          }
        }

        .Collapsible__contentInner {
          padding: 10px;
        }

        .products-container {
          // margin-left: -7px;
          // margin-right: -7px;
          padding-top: 10px;

          .row {
            display: flex;
            align-items: stretch;

            .product-card {
              // width: 33%;
              // display: flex;
              // position: relative;
              margin-bottom: 13px;
              width: 100%;
              a {
                text-decoration: none;
                height: 100%;
                width: 100%;
                cursor: pointer;

                .product-container {
                  display: flex;
                  height: 100%;
                  border: 1px solid rgba(77, 48, 48, 0.1);
                  border-radius: 8px;

                  .product-image {
                    margin: 8px 0 8px 8px;
                    width: 35%;
                    background-repeat: no-repeat;
                    background-position: 0 50%;
                    background-size: cover;
                    border-radius: 20px 20px 0 0;

                    &.disabled-image {
                      filter: grayscale(100%);
                    }
                  }

                  .product-text {
                    // background-color: $primaryLemon;
                    padding: 16px;
                    width: 65%;
                    height: 110px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    h3 {
                      margin: 0;
                      font-size: 18px;
                      font-weight: 600;
                    }
                  }
                }
              }
            }

            .product-card:hover {
              opacity: 0.9;
            }
          }

          @media (max-width: 600px) {
            .row {
              flex-direction: column;

              .product-card {
                // width: calc(100% - 14px);
              }
            }
          }
        }
        .retail-add {
          border-radius: 8px;
          cursor: pointer;
          width: 100%;
        }
      }
    }
  }

  .next-module-header {
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: space-between;
    p:first-of-type {
      font-size: 20px;
    }
  }

  .product-image-container {
    padding: 5px;
  }

  .product-yellow {
    width: 100%;
    background-color: $primaryLemon;
    border-radius: 0 8px 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.pupford-footer-image {
  width: 100%;
  position: absolute
}