@import "../../../colors";

.Header {
  box-shadow: 0 4px 4px #B0653314;
  padding: 24px;
  position: relative;
  width: 100%;
  z-index: 10;
  .coupon-banner {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $brandCherry;
    color: $brandCream;
    width: 100%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    img {
      margin-right: 10px;
    }
  }
  .header-content {
    display: flex;
    align-items: center;
    .header-menu-control {
      margin: 3px 14px 0 0;
      cursor: pointer;
    }
    img {
      margin-right: 10px;
    }
  }
  .header-menu {
    background-color: $brandCream;
    width: 100%;
    padding: 0px 24px;
    position: absolute;
    right: 0px;
    .menu-module-link {
      text-decoration: none;
      color: $brandCherry;
      margin: 24px 0 24px 0;
      p {
        margin: 0;
      }
      .module-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 4px;
        margin-top: 24px;
      }
      .module-divider {
        border: 1px solid $primarySalmon;
        margin-top: 24px;
      }
    }
  }
}

.coupon-modal {
  background-color: teal;
}

