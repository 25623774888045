@import "../../../colors";

.GetStarted {
  .train-img {
    height: 350px;
    margin-top: -50px;
  }
  .content-container {
    margin-top: -100px;
    text-align: center;
  }
  .content-title {
    font-size: 25px;
    font-weight: 600;
  }
}