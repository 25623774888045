@import "colors";

@font-face {
  font-family: Buenos Aires;
  src: url(https://pupford-fonts.b-cdn.net/BuenosAiresVariableUpright.ttf) format("truetype");
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: Buenos Aires;
  src: url(https://pupford-fonts.b-cdn.net/BuenosAiresVariableUpright.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

// @font-face {
// 	font-family: Visby;
// 	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-webfont.woff) format("woff");
// 	font-weight: 500;
// 	font-style: normal
// }

// @font-face {
// 	font-family: Visby;
// 	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-oblique-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-medium-oblique-webfont.woff) format("woff");
// 	font-weight: 500;
// 	font-style: italic
// }

// @font-face {
// 	font-family: Visby;
// 	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-webfont.woff) format("woff");
// 	font-weight: 600;
// 	font-style: normal
// }

// @font-face {
// 	font-family: Visby;
// 	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-oblique-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-bold-oblique-webfont.woff) format("woff");
// 	font-weight: 600;
// 	font-style: italic
// }

// @font-face {
// 	font-family: Visby;
// 	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-webfont.woff) format("woff");
// 	font-weight: 800;
// 	font-style: normal
// }

// @font-face {
// 	font-family: Visby;
// 	src: url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-oblique-webfont.woff2) format("woff2"), url(https://dwvecv5z9ozyt.cloudfront.net/fonts/visby-cf-heavy-oblique-webfont.woff) format("woff");
// 	font-weight: 800;
// 	font-style: italic
// }

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Buenos Aires,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fefefe;
}

body.modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label,
legend {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
  font-weight: 600;
}

fieldset {
  border: none;
  padding: 0;
}

textarea,
input[type=email],
input[type=text],
input[type=number],
input[type=password],
input[type=date] {
  line-height: 1.4;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  color: #404040;
  box-shadow: none;
  border: 1px solid #bfbfbf;
  padding: 10px 12px;
  font-size: 16px;
  width: 100%;
}

input[type=email]:focus,
input[type=text]:focus,
input[type=number]:focus,
input[type=password]:focus {
  outline-width: 0;
  border-color: #61bdf7;
}

button {
  background-color: #ff3312;
  color: #fff;
  border-radius: 4px;
  padding: 0;
  border: none;
  font-size: 0.875rem;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &.secondary {
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
  }

  &.success {
    background-color: #2BB673;
  }
}

button:hover {
  background-color: #000;
}

button.secondary:hover {
  background-color: #bbb;
}

button.success:hover {
  background-color: #2BB673;
  opacity: 0.8;
}

button:focus {
  outline:0;
}

button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

select {
	background: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%), linear-gradient(to right, #fff, #fff);
	background-position: calc(100% - 21px) calc(1em + 5px), calc(100% - 16px) calc(1em + 5px), 100% 0;
	background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
	background-repeat: no-repeat;
	line-height: 1.4;
	background-color: #ffffff;
	border-radius: 4px;
	position: relative;
	color: #404040;
	box-shadow: none;
	border: 1px solid #bfbfbf;
	padding: 8px 12px;
	width: 100%;
	font-size: 16px;
	margin: 0;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	line-height: 1.65;
}

.input-container {
  margin: 20px 0;
}

select:focus {
  outline: none;
}

.well {
  background: #FFFFFF;
  border: 1px solid $borderDark;
  border-radius: 6px;
  padding: 20px 25px;

  &.well-secondary {
    border-color: rgba(255, 51, 18, 0.2);
  }

  &.well-light {
    border-color: $borderLight;
  }

  &.well-large {
    border-radius: 20px;
  }
}

.behavior-modal {
  text-align: center;

  .behavior-modal-title {
    font-weight: bold;
    font-size: 18px;
  }

  .behavior-modal-text {
    font-size: 16px;
  }

  .behavior-modal-rating {
    margin: 20px 0;
  }
}

.content-max-width {
  width: 700px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.main-container-min-height {
  min-height: 100vh;
}
