@import "../../../colors.scss";

.Home {
  position: relative;

  .link-bar-outer {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
  }

  .link-bar {
    overflow-x: auto;
    white-space: nowrap;

    .category-link {
      padding: 12px;
      display: inline-block;
      font-size: 14px;
      color: #333;
      text-decoration: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .featured-course-container {
    max-height: 500px;
    overflow: hidden;
    position: relative;


    @media (max-width: 660px) {
      max-height: 1000px;
    }

    img {
      width: 100%;
    }

    .featured-course-banner {
      align-items: center;
      color: $darkSalmon;
      display: flex;
      flex-direction: column;
      padding: 25px;

      @media (max-width: 1050px) {
        transform: translate(0, 0);
        top: auto;
        bottom: 50px;
        right: 50px;
      }

      @media (max-width: 660px) {
        top: auto;
        bottom: auto;
        right: auto;
        position: relative;
        margin: 10px;
      }

      h1 {
        font-family: SuisseWorks, Georgia, Times, "times new roman", serif, "apple color emoji", "segoe ui emoji", "segoe ui symbol";
        font-weight: 700;
        font-size: 34px;
        line-height: 1.25;
        letter-spacing: -0.05rem;
        margin: 0;
        margin-bottom: 10px;
      }

      p {
        margin: 0;
        font-size: 15px;
        line-height: 1.7;
      }
    }
  }

  .content-container {
    @media (max-width: 1240px) {
      padding: 10px;
    }

    h2 {
      color: #1C1D1F;

      &.alternate-heading {
        font-family: SuisseWorks, Georgia, Times, "times new roman", serif, "apple color emoji", "segoe ui emoji", "segoe ui symbol";
        font-weight: 700;
        font-size: 30px;
        line-height: 1.25;
        letter-spacing: -0.05rem;
        margin-bottom: 35px;
      }
    }

    .modules-container {
      margin-bottom: 40px;

      .link-tile {
        width: 245px;
        display: inline-block;
        margin-right: 20px;
        text-decoration: none;
        padding-bottom: 10px;
        overflow: hidden;

        .module-image-wrapper {
          background-color: #1C1D1F;
          border: 1px solid #D1D7DC;
          overflow: hidden;
          height: 139px;

          img {
            width: 100%;
            display: block;
          }
        }

        h3 {
          color: #1C1D1F;
          margin: 5px 0;
          font-size: 18px;
          width: 245px;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          overflow: hidden;
        }

        p {
          color: #6A6F73;
          margin: 0;
          font-size: 12px;
          width: 245px;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          overflow: hidden;
        }
      }
    }
  }
}
